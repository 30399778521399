import React, {Fragment} from 'react';
import LoanCalculator from "./LoanCalculator/LoanCalculator";
import "./App.css"
import logo from './logo.svg'
const App = () => {
    return (
        <Fragment>
            <div className="container" >
                <div className="row d-flex  justify-content-center">
                    <img alt="logo" className="logo mt-4" src={logo}/>
                    <h3 className="text-center home-title m-3">Loan Amortization Calculator</h3>
                    <div className="col-11 col-md-10 col-lg-10 col-sm-12 shadow-sm content-section bg-white"  >
                        <LoanCalculator/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default App;