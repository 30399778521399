import React, {Component,Fragment} from 'react';
import CurrencyFormat from 'react-currency-format';
import { Doughnut  } from 'react-chartjs-2';
import {FaSquareFull} from "react-icons/all";
import './bootstrap.min.css';
import './LoanCalculator.css';

class LoanCalculator extends Component {

    constructor(props) {
        super(props);
        this.state={
            Principle:1000000,
            Interest:9,
            Years:10,
            TotalEMI:12667,
            TotalPrinciple:1000000,
            TotalInterest:520109,
            TotalPayable: 1520109,
        }
    }

    // Principle Amount Range On Change
    PrincipleOnChange=(e)=>{
        let Principle=Number(e.target.value);
        let Interest=Number(this.state.Interest);
        let Months=(Number(this.state.Years))*12;
        this.setState({Principle:Principle});
        this.CalculateLoanDetails(Interest,Principle,Months);
    }

    // Loan Details Calculation
    CalculateLoanDetails=(Interest,Principle,Months)=>{
        let i = Interest / 100.0 / 12;
        let EMI = this.rnd(Principle * (i + i / (Math.pow(1+i, Months) -1 )));
        let TotalPayable=(EMI*Months).toFixed(2);
        let TotalInterest=(TotalPayable-Principle).toFixed(2);
        this.setState({
            TotalEMI:EMI,
            TotalPrinciple:Principle,
            TotalPayable:TotalPayable,
            TotalInterest:TotalInterest,
        })
    }

    // Loan Tenure Year On Change
    YearsOnChange=(e)=>{
        let Years=Number(e.target.value);
        this.setState({Years:Years});
        let Months=Years*12;
        let Interest=Number(this.state.Interest);
        let Principle=Number(this.state.Principle);
        this.CalculateLoanDetails(Interest,Principle,Months);
    }

    // Interest Rate On Change
    InterestOnChange=(e)=>{
        let Interest=Number(e.target.value);
        this.setState({Interest:Interest});
        let Months=(Number(this.state.Years))*12;
        let Principle=Number(this.state.Principle);
        this.CalculateLoanDetails(Interest,Principle,Months);
    }


    // Rounding The Number
    rnd=(n)=> {
        return Math.round(n * 100) / 100;
    };


    render() {

        // Doughnut Chart Data
        const data =  {
            datasets: [
                {
                    data: [this.state.TotalPrinciple,this.state.TotalInterest],
                    backgroundColor: ['rgba(20, 115, 230, 1)','rgba(255, 193, 0, 1)']
                },
            ],
        };


        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">

                            {/* Amount Ranges */}
                            <label htmlFor="customRange1" className="calculator-range-label">  <CurrencyFormat value={this.state.Principle} displayType={'text'} thousandSeparator={true}  renderText={value => <div className="m-0 p-0">Loan Amount <b>$ {value}</b></div>}/></label>
                            <input step="100000" defaultValue="1000000" min="100000" max="30000000" onChange={this.PrincipleOnChange} type="range" className="form-range mb-2 calculator-range" id="customRange1"/>

                            <label htmlFor="customRange1" className="calculator-range-label">  <CurrencyFormat value={this.state.Years} displayType={'text'} thousandSeparator={true}  renderText={value => <div className="m-0 p-0">Loan Tenure <b> {value} Years</b></div>}/></label>
                            <input step="1" defaultValue="10" min="1" max="20" onChange={this.YearsOnChange} type="range" className="form-range mb-2 calculator-range" id="customRange1"/>

                            <label htmlFor="customRange1" className="calculator-range-label">  <CurrencyFormat value={this.state.Interest} displayType={'text'} thousandSeparator={true}  renderText={value => <div className="m-0 p-0">Interest Rate <b> {value} %</b></div>}/></label>
                            <input step="1"  defaultValue="9" min="1" max="20" onChange={this.InterestOnChange} type="range" className="form-range mb-2 calculator-range" id="customRange1"/>

                            {/* Monthly EMI */}
                            <h4 className="calculator-emi-number  mt-3"><CurrencyFormat value={this.state.TotalEMI} displayType={'text'} thousandSeparator={true}  renderText={value => <div className="m-0 p-0">$ {value}</div>}/></h4>
                            <p className="calculator-emi-title">Equal Monthly Installment</p>

                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12" >

                            {/* Doughnut Ratio Chart */}
                            <div className="w-100">
                                <Doughnut   options={{responsive: true, maintainAspectRatio: false,}} data={data} type=""/>
                            </div>


                            {/* Loan Summary  Div */}
                            <div className="container-fluid m-0 p-0 mt-2">
                                <div className="row  m-0 p-0 d-flex justify-content-center">
                                    <div className="col-md-12 col-12 m-0 p-0 calculator-summary-div col-lg-12">
                                        <table className="table table-sm ">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <h5 className="calculator-summary-title"><FaSquareFull className="text-primary mx-1"/> Principle : </h5>
                                                    </td>
                                                    <td>
                                                        <h5 className="calculator-summary-title"><CurrencyFormat value={this.state.TotalPrinciple} displayType={'text'} thousandSeparator={true}  renderText={value => <div className="m-0 p-0"><b>$ {value} </b></div>}/></h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="calculator-summary-title"><FaSquareFull className="text-warning mx-1"/> Interest : </h5>
                                                    </td>
                                                    <td>
                                                        <h5 className="calculator-summary-title"><CurrencyFormat value={this.state.TotalInterest} displayType={'text'} thousandSeparator={true}  renderText={value => <div className="m-0 p-0"><b>$ {value} </b></div>}/></h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h5 className="calculator-summary-title"><FaSquareFull className="text-dark mx-1"/> Payable : </h5>
                                                    </td>
                                                    <td>
                                                        <h5 className="calculator-summary-title"><CurrencyFormat value={this.state.TotalPayable} displayType={'text'} thousandSeparator={true}  renderText={value => <div className="m-0 p-0"><b>$ {value} </b></div>}/></h5>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
export default LoanCalculator;